import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

const StyledButton = styled(Link)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? '#fc741c' : '#010606')};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
  color: ${({ dark }) => (dark ? '#010606' : '#fff')};
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? '#fff' : '#fc741c')};
  }
`;

function handleClick(url) {
  // Visit the specified URL
  window.location.href = url;
}

export const Button = ({ to, smooth, duration, onClick, primary, big, dark, fontBig, children }) => {
  const handleClickInternal = () => {
    if (onClick) {
      onClick();
    }
    handleClick(to); // Visit the specified URL
  };

  return (
    <StyledButton to={to} smooth={smooth ? 'true' : 'false'} duration={duration} onClick={handleClickInternal} primary={primary ? 1 : 0} big={big ? 1 : 0} dark={dark ? 1 : 0} fontBig={fontBig ? 1 : 0}>
      {children}
    </StyledButton>
  );
};


