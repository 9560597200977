import React, { useState, useEffect } from 'react';
import {
  ServicesContainer,
  ServicesCard,
  ServicesH1,
  ServicesH2,
  ServicesP,
  ServicesWrapper,
  ServicesIcon,
  CardFront,
  CardBack
} from './ServiceElements';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { exampleTeamMembers } from './teamMembers';

const Team = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  // Configuration for the react-slick slider
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    adaptiveHeight: true
  };

  return (
    <ServicesContainer id="services">
      <ServicesH1>Our Team</ServicesH1>
      {isMobile ? (
        // Render the slider only in mobile view
        <Slider {...sliderSettings}>
          {/* Render individual service cards */}
          {exampleTeamMembers.map((member, index) => (
            <ServicesCard
              key={index}
              onMouseEnter={() => handleHover(index)}
              onMouseLeave={() => handleHover(null)}
            >
              <CardFront>
                <ServicesIcon src={member.image} />
                <ServicesH2>{member.name}</ServicesH2> {/* Display member name on the front */}
              </CardFront>
              {hoveredIndex === index && (
                <CardBack>
                  <ServicesH2>{member.name}</ServicesH2>
                  <ServicesP>{member.role}</ServicesP>
                  <ServicesP>{member.description}</ServicesP>
                </CardBack>
              )}
            </ServicesCard>
          ))}
        </Slider>
      ) : (
        // Render the original grid layout in desktop view
        <ServicesWrapper>
          {exampleTeamMembers.map((member, index) => (
            <ServicesCard
              key={index}
              onMouseEnter={() => handleHover(index)}
              onMouseLeave={() => handleHover(null)}
            >
              <CardFront>
                <ServicesIcon src={member.image} />
                <ServicesH2>{member.name}</ServicesH2> {/* Display member name on the front */}
              </CardFront>
              {hoveredIndex === index && (
                <CardBack>
                  <ServicesH2>{member.name}</ServicesH2>
                  <ServicesP>{member.role}</ServicesP>
                  <ServicesP>{member.description}</ServicesP>
                </CardBack>
              )}
            </ServicesCard>
          ))}
        </ServicesWrapper>
      )}
    </ServicesContainer>
  );
};

export default Team;
