import React from 'react'
import {
    FooterContainer,
    FooterLink,
    FooterLinkContainer,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLinkWrapper,
    FooterWrap,
    SocialIconLink,
    SocialIcons,
    SocialLogo,
    SocialMedia,
    SocialMediaWrap,
    WebsiteRights
} from './FooterElements'
import {
    FaTelegramPlane,
    FaTwitter,
    FaGithubAlt
} from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll';

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop()
    }

    return (
        <>
            <FooterContainer>
                <FooterWrap>
                    <FooterLinkContainer>
                        <FooterLinkWrapper>
                            <FooterLinkItems>
                                <FooterLinkTitle>About Us</FooterLinkTitle>
                                <FooterLink href="https://bitmos.io/howitworks">How it Works</FooterLink>
                                <FooterLink href="https://litepaper.bitmos.io" target="_blank">Litepaper</FooterLink>
                            </FooterLinkItems>
                            <FooterLinkItems>
                                <FooterLinkTitle>Resources</FooterLinkTitle>
                                <FooterLink href="https://gateway.bitmos.io">Gateway</FooterLink>
                                <FooterLink href="https://gateway.bitmos.io">Explorer</FooterLink>
                                <FooterLink href="https://litepaper.bitmos.io">Documentations</FooterLink>
                                <FooterLink href="https://unisat.io">Unisat Wallet</FooterLink>
                            </FooterLinkItems>
                        </FooterLinkWrapper>
                        <FooterLinkWrapper>
                            <FooterLinkItems>
                                <FooterLinkTitle>Contact Us</FooterLinkTitle>
                                <FooterLink href="mailto:krunk@bitmos.io">Email</FooterLink>
                                <FooterLink href="https://twitter.com/_bitmos_">Twitter</FooterLink>
                            </FooterLinkItems>
                        </FooterLinkWrapper>
                    </FooterLinkContainer>
                    <SocialMedia>
                        <SocialMediaWrap>
                            <SocialLogo to="/" onClick={toggleHome}>
                                Bitmos
                            </SocialLogo>
                            <WebsiteRights>{new Date().getFullYear()} All rights reserved.
                            </WebsiteRights>
                            <SocialIcons>
                                <SocialIconLink
                                    href="https://t.me/bitmos_community"
                                    target="_blank"
                                    aria-label="Telegram">
                                    <FaTelegramPlane />
                                </SocialIconLink>
                                <SocialIconLink
                                    href="https://twitter.com/_bitmos_"
                                    target="_blank"
                                    aria-label="Twitter">
                                    <FaTwitter />
                                </SocialIconLink>
                                <SocialIconLink
                                    href="https://litepaper.bitmos.io"
                                    target="_blank"
                                    aria-label="Gitbook">
                                    <FaGithubAlt />
                                </SocialIconLink>
                            </SocialIcons>
                        </SocialMediaWrap>
                    </SocialMedia>
                </FooterWrap>
            </FooterContainer>
        </>
    )
}

export default Footer
