import React, { useState } from 'react'
import ComingSoonNavbar from '../Components/ComingSoonNavbar/Index'   
import ComingSoonSidebar from '../Components/ComingSoonSideBar/Index'
import ComingSoon from '../Components/ComingSoon/Index'
import Footer from '../Components/Footer/Index'

const Gateway = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <ComingSoonSidebar isOpen={isOpen} toggle={toggle} />
        <ComingSoonNavbar />
            <ComingSoon />
            <Footer />
        </>
    )
}

export default Gateway
