import React, { useState, useEffect } from 'react';
import {
  HowItWorksContainer,
  HowItWorksBg,
  HowItWorksVideoBg,
  HowItWorksArrowForward,
  HowItWorksContent,
  HowItWorksP,
  HowItWorksTitle,
  HowItWorksBtnWrapper,
  HowItWorksArrowRight,
  LogoContainer,
  LogoImage
} from '../HowItWorks/HowItWorksElements';
import { Button } from '../../Utilities/ButtonElements';
import Video from '../../Videos/video.mp4';
import VideoCompressed from '../../Videos/video_compressed.mp4';

const HowItWorks = () => {
  const [hover, setHover] = useState(false);
  const [videoSource, setVideoSource] = useState(Video);

  const onHover = () => {
    setHover(!hover);
  };

  useEffect(() => {
    const getInternetSpeed = () => {
      // Simulating a delay to fetch internet speed
      setTimeout(() => {
        const internetSpeed = 1; // Placeholder for internet speed measurement (e.g., in Mbps)
        const threshold = 2; // Define your threshold for slow internet speed (e.g., 2 Mbps)
  
        if (internetSpeed < threshold) {
          setVideoSource(VideoCompressed);
        } else {
          setVideoSource(Video);
        }
      }, 2000); // Simulating a delay of 2 seconds for the internet speed measurement
    };

    getInternetSpeed();
  }, []);

  return (
    <>
      <HowItWorksContainer>
        <HowItWorksBg>
          <HowItWorksVideoBg autoPlay loop muted src={videoSource} type='video/mp4' />
        </HowItWorksBg>
        <HowItWorksContent>
          <LogoContainer>
            <LogoImage src="/images/logos/main_logo.png" alt="Bitmos logo" />
          </LogoContainer>
          <HowItWorksP>
            How it Works
          </HowItWorksP>
          <HowItWorksBtnWrapper>
            <Button
              to="https://litepaper.bitmos.io"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="true"
              dark="true"
            >
              Litepaper {hover ? <HowItWorksArrowForward src="path-to-arrow-forward-image" /> : <HowItWorksArrowRight src="path-to-arrow-right-image" />}
            </Button>
          </HowItWorksBtnWrapper>
        </HowItWorksContent>
      </HowItWorksContainer>
    </>
  );
};

export default HowItWorks;
